
















































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';

@Component({
  components: {
    OpAppDownloadPanel
  }
})
export default class P0920 extends Vue {
  isNoticeOpen = false; //各条件の注意事項の表示フラグ
}
